@import url('https://fonts.googleapis.com/css2?family=Proza+Libre&family=Raleway:wght@300&display=swap');

#contact input[type="text"],
#contact input[type="tel"],
#contact input[type="email"],
#contact input[type="text"],
#contact textarea,
#contact button[type="submit"] {
  font: 400 12px/16px "Open Sans", Helvetica, Arial, sans-serif;
}

#contact {
  padding: 30px 30px 30px 30px;
  border-radius: 15px;
  border: 2px solid black;
}

#contact input[type="text"],
#contact input[type="tel"],
#contact input[type="email"],
#contact input[type="text"],
#contact textarea {
  width: 100%;
  margin: 5px 0px 5px 0px;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #e3e3e3;
  background: rgba(211, 211, 211, 0.425);
}

#contact input[type="submit"] {
  
  cursor: pointer;
  border: none;
  background: rgba(211, 211, 211, 0.425);
  color: #000;
  border-radius: 11px;
  padding: 10px 40px 10px 40px;
  font-size: 15px;
}


#contact input[type="submit"]:hover {
  background: red;
  color: #fff;
  
  
}



.lead{
  padding: 0 100px 0 100px;
  text-align: center;
  font-family: Proza Libre;
font-size: 50px;

font-weight: 700;
line-height:55px; 
letter-spacing: -1.28px;
}
.leads{
  width: 800px;
}
.bottomform{
  padding: 0 300px 0 300px;
  text-align: left;
}
.talkto{
  padding: 0 280px 0 280px;
}
.watsapp{
  
  display: flex;
  text-align: left;
  align-items: center;
}
@media only screen and (max-width: 1000px) {
  .leads{
    width: 350px;
  }
  .lead{
    padding: 0px 0px 0px 0px;
    font-size: 33px;
    line-height: 50px; 
  }
  .bottomform{
    padding: 10px 10px 10px 10px;
    text-align: left;
  }
  .talkto{
    padding: 10px 10px 10px 10px;
  }
  .watsapp{
    
    display: flex;
    text-align: left;
    align-items: center;
   margin-top: -20px;
  }
}

h5 {
  text-decoration-line:  underline;
  text-decoration-color: #4D98C0;
}




.carousel-control-next-icon { 
    background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23009be1' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); 
    
}
.carousel-control-prev-icon { 
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23009be1' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    
}
.pooSpan{
  font-weight: bolder;
}
.poo{
  color: #EBF8FF;

text-align: center;
font-family: Proza Libre;
font-size: 18px;
font-style: normal;

line-height: 25px; 
letter-spacing: -0.48px;

}

.mainparent{
  
  padding:0 18px ;
  
  border-radius:22px ;
 
}


